import { DiscountType, DocumentType } from '@innedit/innedit-type';
import { DiscountData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemDiscount: FC<ListItemProps<DiscountType, DiscountData>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<DiscountType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      displayActionId
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
      subLabel={`${doc.value}`}
    />
  );
};

export default ListItemDiscount;
